import { Stack } from 'react-bootstrap';
import './Hotel.css';
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import abades_1 from '../img/hotel/abades_1.webp'
import abades_2 from '../img/hotel/abades_2.webp'
import abades_3 from '../img/hotel/abades_3.webp'
import abades_4 from '../img/hotel/abades_4.webp'
import luna_1 from '../img/hotel/luna_1.jpg'
import luna_2 from '../img/hotel/luna_2.jpg'
import luna_3 from '../img/hotel/luna_3.jpg'
import luna_4 from '../img/hotel/luna_4.jpg'
import euro_real_1 from '../img/hotel/euro_real_1.jpg'
import euro_real_2 from '../img/hotel/euro_real_2.jpg'
import euro_real_3 from '../img/hotel/euro_real_3.jpg'
import euro_real_4 from '../img/hotel/euro_real_4.jpg'
import aurea_1 from '../img/hotel/aurea_1.png';
import aurea_2 from '../img/hotel/aurea_2.jpg';
import aurea_3 from '../img/hotel/aurea_3.jpg';
import aurea_4 from '../img/hotel/aurea_4.jpg';
import anton_1 from '../img/hotel/anton_1.jpeg';
import anton_2 from '../img/hotel/anton_2.jpg';
import anton_3 from '../img/hotel/anton_3.jpg';
import anton_4 from '../img/hotel/anton_4.jpg';

export const Hotel = () => {
    const { t } = useTranslation();
   
      const abades: any[] = [
        abades_1,
        abades_2,
        abades_3,
        abades_4
    ]

    const luna: any[] = [
        luna_1,
        luna_2,
        luna_3,
        luna_4
    ]

    const euro_real: any[] = [
        euro_real_1,
        euro_real_2,
        euro_real_3,
        euro_real_4
    ]

    const aurea: any[] = [
        aurea_1,
        aurea_2,
        aurea_3,
        aurea_4
    ]

    const anton: any[] = [
        anton_1,
        anton_2,
        anton_3,
        anton_4
    ]

   
    return (
      <Stack className="hotel">
       <h2>{t("hotelpage.bus")}</h2>
       <div className="hotels">
            <div className="one_hotel" key={`hotel_abades`}>
                <h4 className="hotel_name"><a rel="noreferrer" href="https://maps.app.goo.gl/8kw1huiYS8dxknWM6" target="_blank">Abades nevada palace</a></h4>
                <div className="hotel_ubi">
                        <Carousel>
                        {abades.map( m=> (
                                <div>
                                <img alt={m} src={m} />
                            </div>
                            ))} 
                    </Carousel>
                </div>
                <div className="hotel_check">Check-in: {t("hotelpage.check_in")} | Check-Out: {t("hotelpage.check_out")}</div>
                <div className="hotel_rate">~111,36€ ({t("hotelpage.rate")})</div>
                <div className="hotel_code"><b>{t("hotelpage.code")}: BodaMariayAlberto</b></div>
                <div className="hotel_website"><a rel="noreferrer" className="hotel_website_link" href="https://www.abadeshoteles.com/es/bookcore/availability/rooms/nevadabades/?cp=BodaMariayAlberto">Website</a></div>
                <div className="hotel_description">{t("hotelpage.abades_description")}</div>
            </div>
            <div className="one_hotel" key={`hotel_abadess`}>
                <h4 className="hotel_name"><a rel="noreferrer" href="https://maps.app.goo.gl/Ch8422QKooEDCmY48" target="_blank">Luna de Granada</a></h4>
                <div className="hotel_ubi">
                        <Carousel>
                        {luna.map( m=> (
                                <div>
                                <img alt={m} src={m} />
                            </div>
                            ))}  
                    </Carousel>
                </div>
                <div className="hotel_check" key={`hotel_abaddes`}>Check-in: {t("hotelpage.check_in")} | Check-Out: {t("hotelpage.check_out")}</div>
                <div className="hotel_rate">89€ individual - 115€ ({t("hotelpage.rate")}) <br/>
                125 € Triple - 140€ Familiar <br/>
                ({t("hotelpage.desayuno")})
                </div>
                <div className="hotel_code"><b>{t("hotelpage.code")}: BODA NO HOTEL ALBERTO JESÚS</b></div>
                <div className="hotel_website"><a className="hotel_website_link" href="https://www.delunahotels.com/granada/hoteles/gran-hotel-luna-de-granada">Website</a></div>
                <div className="hotel_description">{t("hotelpage.luna_description")}</div>
            </div>  
            <div className="one_hotel" key={`hotel_adfbades`}>
                <h4 className="hotel_name"><a rel="noreferrer" href="https://maps.app.goo.gl/cdqj6AJbEyS4wM3CA" target="_blank">Eurostars Puerta Real</a></h4>
                <div className="hotel_ubi">
                        <Carousel>
                        {euro_real.map( m=> (
                                <div>
                                <img alt={m} src={m} />
                            </div>
                            ))}  
                    </Carousel>
                </div>
                <div className="hotel_check">Check-in: {t("hotelpage.check_in")} | Check-Out: {t("hotelpage.check_out")}</div>
                <div className="hotel_rate">~210€ ({t("hotelpage.rate")})</div>
                <div className="hotel_code"><b>{t("hotelpage.code")}: BODAMA</b></div>
                <div className="hotel_website"><a rel="noreferrer" className="hotel_website_link" href="https://www.eurostarshotels.com/eurostars-puerta-real.html?referer_code=lb0gg00yx&utm_source=google&utm_medium=business&utm_campaign=lb0gg00yx">Website</a></div>
                <div className="hotel_description">{t("hotelpage.euro_real")}</div>
            </div>   
            <div className="one_hotel" key={`hoteql_abades`}>
                <h4 className="hotel_name"><a rel="noreferrer" href="https://maps.app.goo.gl/TqJBV8CqKvj5KvzQ8" target="_blank">Aurea Catedral</a></h4>
                <div className="hotel_ubi">
                        <Carousel>
                        {aurea.map( m=> (
                                <div>
                                <img alt={m} src={m} />
                            </div>
                            ))}  
                    </Carousel>
                </div>
                <div className="hotel_check">Check-in: {t("hotelpage.check_in")} | Check-Out: {t("hotelpage.check_out")}</div>
                <div className="hotel_code"><b>{t("hotelpage.code")}: BODAMA</b></div>
                <div className="hotel_website"><a rel="noreferrer" className="hotel_website_link" href="https://www.eurostarshotels.com/aurea-catedral.html?referer_code=lb0gg00yx&utm_source=google&utm_medium=business&utm_campaign=lb0gg00yx">Website</a></div>
                <div className="hotel_description">{t("hotelpage.euro_real")}</div>
            </div>  
            <div className="one_hotel" key={`hotel_ab4ades`}>
                <h4 className="hotel_name"><a rel="noreferrer" href="https://maps.app.goo.gl/uwc5oUX61QnMP4RZ6" target="_blank">Eurostars San Antón</a></h4>
                <div className="hotel_ubi">
                        <Carousel>
                        {anton.map( m=> (
                                <div>
                                <img alt={m} src={m} />
                            </div>
                            ))}  
                    </Carousel>
                </div>
                <div className="hotel_check">Check-in: {t("hotelpage.check_in")} | Check-Out: {t("hotelpage.check_out")}</div>
                <div className="hotel_rate">~238€ ({t("hotelpage.rate")})</div>
                <div className="hotel_code"><b>{t("hotelpage.code")}: BODAMA</b></div>
                <div className="hotel_website"><a rel="noreferrer" className="hotel_website_link" href="https://www.eurostarshotels.com/eurostars-san-anton.html">Website</a></div>
                <div className="hotel_description">{t("hotelpage.euro_anton")}</div>
            </div>       
       </div>
      </Stack>
    );
  };